import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '../shared-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppLoaderComponent } from './app-loader/app-loader.component';

const components = [
  AppLoaderComponent
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    SharedMaterialModule
  ],
  declarations: components,
  entryComponents: [AppLoaderComponent],
  exports: components
})
export class SharedComponentsModule { }