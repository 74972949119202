export const APP_VARIABLES = {
    // payKey: 'rzp_test_XAwbRDSItDjjI0',
    payKey: 'rzp_live_3xn2X3xKODEgt4',
    cashFreeApiId: '4262737eaabbda066523c1e8272624',
    cashFreeScreteKey: 'c36f6a15c1b1eb57be7a9cf54602c4fc921de94e',
    // cashFreeApiId: "87728fe382a6ef3d3b665d08f82778",
    // cashFreeScreteKey: "eaae89a9855afc7a5174c329f62b1f1d80beb303",
    userId: 'USERID',
    adminProfile: 'ADMINPROFILE',
    isAccountCreated: 'ACCOUNTCREATED',
    login: 'LOGIN',
    token: 'TGJWTTOKEN',
    proamt: 'PROAMT',
    promoText: 'PROMOTEXT',
    discountAmt: 'DISCOUNTAMT',
    difPage: 'DIFPAGE',
    table: {
        filtering: {
            pageSizeOptions: [10, 25, 50],
        }
    },
    dateFormat: 'DD-MMM-YYYY',
    selectSeat: 'SELECTSEAT',
    pickupId: 'PICKUPID',
    dropID: 'DROPID',
    pickup: 'PICKUP',
    drop: 'DROP',
    serviceId: 'SERVICEID',
    insuranceAmount: '15',
    totalFare: 'TOTALFARE',
    busId: 'BUSID',
    routeId: 'ROUTEID',
    busIndex: 'BUSINDEX',
    masterdata: 'MASTERDATA',
    validators: {
        image: /(.*?)\.(jpg|jpeg|png)$/,
        integer: /^-?(0|[1-9]\d*)?$/,
        decimalTwoDigitOnly: /^\d{1,1000}(\.\d{1,2})?$/, // allows plus value and two digit
        decimal: /^\-?(\d+\.?\d*|\d*\.?\d+)$/, // allows + or - values 
        sixDigitInteger: /^[0-9]{1,6}$/,
        tenDigitInteger: /^[0-9]{10}$/,
        alphaNumeric: /^[0-9a-zA-Z]+$/, // RSDFS3454fgfet 
        lettersOnly: /^[A-Za-z]+$/, // ABCabcRtvd
        imageAndPdf: /(.*?)\.(jpg|jpeg|png|bmp|pdf)$/,
        removeWhitespace: /^[^ ][\w\W ]*[^ ]/,
        removeWSWLetter: /^\S$|^\S[\s\S]*\S$/ // remove white space prefix, suffix and center of the letters
    }

}