import { ApiService } from './api.service';
import { AuthGuard } from './auth-guard.service';
import { UserService } from './user.service';
import { ErrorHandlerService } from './error-handler.service';
import { HttpCacheService } from './http-cache.service';
import { ToastService } from './toast.service';
import { ConfigService } from './config.service';
import { TokenService } from './token.service';
import { CommonService } from './common.service';

export const serviceProvider = [
    ApiService,
    AuthGuard,
    UserService,
    TokenService,
    ErrorHandlerService,
    HttpCacheService,
    ToastService,
    ConfigService,
    CommonService
];