// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: 'https://busapi.technogenesis.in/api/C1/',
  // apiURL: 'https://busnow.co.in/portal/api/C1/',
  //apiURL: 'https://busnow.co.in/testing/portal/api/C1/',
  userImageBaseUrl: 'https://busnow.co.in/portal/public/user_img/',
  busCategoryImgUrl: 'https://busnow.co.in/portal/',
  busImageUrl: 'http://tgsstest.com/bus_now/',
  tnApiURl: 'http://apistaging.ticketsimply.com/',
  apiKey: "TSBUSAPINOW*2020",
  //paymentapi: 'https://test.cashfree.com/api/v1/'
  paymentapi: '"https://api.cashfree.com/api/v2/cftoken/order',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
