import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})

export class CommonService {
    constructor(
        private apiService: ApiService,
    ) { }



    getCity(): Observable<any> {
        return this.apiService.get('cityGet')
    }

    getIndiaCity(): Observable<any> {
        // return this.apiService.tnget('gds/api/cities.json')
        return this.apiService.get('cityApi')
    }

    getMasterDetail(): Observable<any> {
        // return this.apiService.tnget('/gds/api/masters.json');
        return this.apiService.get('masterApi')
    }

    getFilterCityList(city_name) {
        let formdata = new FormData();
        formdata.append('city_name',city_name)
        return this.apiService.post('citiesFilter',formdata);
      }
}
