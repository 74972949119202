import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSliderModule} from '@angular/material/slider';
import 'hammerjs';
import { IgxSliderModule,  IgxInputGroupModule, } from "igniteui-angular";  
import {MatSelectModule} from '@angular/material/select';


@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		CommonModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		appRoutingModule,
		NgxWebstorageModule.forRoot(),
		FlexLayoutModule,
		BrowserAnimationsModule,
		GalleryModule,
		LightboxModule,
		CoreModule,
		SharedModule,
		MatSliderModule,
		IgxSliderModule,
		IgxInputGroupModule,
		MatSelectModule
	],
	providers: [],
	bootstrap: [AppComponent]
})

export class AppModule { }
