import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { constant } from '../helpers/global.helper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(public localStore: LocalStorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig: any = {};
    const token = this.localStore.retrieve(constant().app.token);
    if (token) {
      headersConfig.Authorization = `Bearer ${token}`;

    }
    headersConfig['api-key'] = `${environment.apiKey}`;

    const request = req.clone({ setHeaders: headersConfig });
    return next.handle(request);
  }
}
