import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { get } from "lodash";
import { TokenService } from './token.service';
import { LocalStorageService } from 'ngx-webstorage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isUserDetailsUpdated = new BehaviorSubject<boolean>(false);


  constructor(
    private apiService: ApiService,
    private tokenService: TokenService,
    private localstore: LocalStorageService,
    private router: Router) {
    this.isAuthenticatedSubject.next(this.tokenService.hasToken());
  }

  // setUserProfile() {
  //   const token = this.tokenService.decodedToken();
  //   let obj = {
  //     name: get(token, 'user_name') ? get(token, 'user_name') : '', mobile: get(token, 'mobile') ? get(token, 'mobile') : '',
  //     image: get(token, 'image') ? environment.userImageBaseUrl + get(token, 'image') : '../../assets/images/avatar.jpg',
  //     email: get(token, 'aud') ? get(token, 'aud') : ''
  //   };
  //   this.localstore.store('userdetails', JSON.stringify(obj));
  // }

  setUpdatedProfile(data) {
    this.localstore.clear('userdetails');
    let obj = {
      name: data.first_name + ' ' + data.last_name, mobile: data.mobile,
      image: data.image ?  data.image : '', email: data.email
    };
    this.localstore.store('userdetails', JSON.stringify(obj));
  }

  updateTokenManually(user) {
    this.tokenService.saveToken(user.token);
    this.localstore.store('sesUsr', JSON.stringify(user))
  }

  getUserProfile() {
    return JSON.parse(this.localstore.retrieve('userdetails'));
  }

  setAuth(user) {
    // this.setUserProfile();
    // Save JWT sent from server in localstorage
    this.tokenService.saveToken(user.token);
    this.localstore.store('sesUsr', JSON.stringify(user))
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
    // this.setUserProfile();
  }

  setRegisterAuth(user) {
    this.localstore.store('sesUsr', JSON.stringify(user))
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.tokenService.destroyToken();
    this.tokenService.clearSessionData();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.router.navigate([`/`])
  }

  removeCurrentToken() {
    this.tokenService.destroyToken();
    this.removeCurrentUser();
    this.localstore.clear('login');
  }

  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.purgeAuth();
  }

  getSessionUser() {
    return JSON.parse(this.localstore.retrieve('sesUsr'));
  }

  getCurrentUser() {
    return JSON.parse(this.localstore.retrieve('sesUsr'));
  }

  removeCurrentUser() {
    this.localstore.clear('sesUsr');
  }
}
