import { Injectable } from '@angular/core';
import { decodedToken } from '../helpers/token.helper';
import { get } from "lodash";
import { constant } from '../helpers/global.helper';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  appSetting = {
    portalType: '',
    customerId: '',
    branchId: '',
    userType: '',
    userId: '',
    roleId: '',
    employeeId: '',
    permission: '',
    domain: '',
    language: '',
    timezone: '',
    currencyCode: '',
    currencySymbol: '',
    themeColour: '',
    themeFont: '',
    dateSeparator: '',
    dateRawFormat: '',
    dateFormat: '',
    timeFormat: '',
  };

  constructor(
  ) {


  }

  init() {
    const token = decodedToken();
    this.appSetting.portalType = get(token, 'type') ? get(token, 'type') : '';
    this.appSetting.userType = get(token, 'user_type') ?? '';
    this.appSetting.userId = get(token, 'acl_user_id') ?? '';
    this.appSetting.customerId = get(token, 'customer_id') ?? '';
    this.appSetting.branchId = get(token, 'branch_id') ?? '';
    this.appSetting.domain = get(token, 'domain_name') ?? '';
    this.appSetting.roleId = get(token, 'acl_role_id') ?? '';
    this.appSetting.employeeId = get(token, 'employee_id') ?? '';
    this.appSetting.permission = get(token, 'permission') ?? '';
    this.appSetting.timezone = get(token, 'timezone') ?? '';
    this.appSetting.currencyCode = get(token, 'currency_code') ?? '';
    this.appSetting.currencySymbol = get(token, 'currency_symbol') ?? '';
  }

  get dateFormat() {
    return this.appSetting.dateRawFormat;
  }

  get portalType() {
    return this.appSetting.portalType;
  }

  get customerId() {
    return this.appSetting.customerId;
  }

  get branchId() {
    return this.appSetting.branchId;
  }

  get domain() {
    return this.appSetting.domain;
  }

  get userType() {
    return this.appSetting.userType;
  }

  get userId() {
    return this.appSetting.userId;
  }

  get roleId() {
    return this.appSetting.roleId;
  }

  get employeeId() {
    return this.appSetting.employeeId;
  }

  get permission() {
    return this.appSetting.permission;
  }

  get language() {
    return this.appSetting.language;
  }

  get timezone() {
    return this.appSetting.timezone;
  }

  get currencyCode() {
    return this.appSetting.currencyCode;
  }
  get currencySymbol() {
    return this.appSetting.currencySymbol;
  }

  get themeColour() {
    return this.appSetting.themeColour;
  }

  get themeFont() {
    return this.appSetting.themeFont;
  }

  get dateSeparator() {
    return this.appSetting.dateSeparator;
  }

  get dateRawFormat() {
    return this.appSetting.dateRawFormat;
  }

  get timeFormat() {
    return this.appSetting.timeFormat;
  }
}
