import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TokenService {

    helper = new JwtHelperService();
    isMenuvisible: boolean;
    isUserLoggedIn = new BehaviorSubject<any>(false);

    constructor(private localstore: LocalStorageService,
        private httpClient: HttpClient) {
        this.isMenuvisible = this.hasToken() && this.isOTPVerified() ? true : false;
        this.isUserLoggedIn.next(this.isMenuvisible);
    }

    hasToken() {
        return !!this.getToken();
    }

    isOTPVerified() {
        return this.localstore.retrieve('login');
    }

    getToken(): String {
        return this.localstore.retrieve('TGJWTTOKEN');
    }

    saveToken(token: String) {
        this.localstore.store('TGJWTTOKEN', token);
    }

    destroyToken() {
        this.localstore.clear('TGJWTTOKEN');
    }

    decodedToken() {
        var myRawToken = this.getToken() ? this.getToken() : '';
        return this.helper.decodeToken(String(myRawToken));
    }

    isTokenExpired(): boolean {
        const myRawToken = this.getToken().toString();
        return this.helper.isTokenExpired(myRawToken);
    }


    tokenExpirationDate(): Date {
        const myRawToken = this.getToken().toString();
        return this.helper.getTokenExpirationDate(myRawToken);
    }

    clearSessionData() {
        this.localstore.clear();
    }

    showMenu() {
        this.isMenuvisible = true;
    }

    hideMenu() {
        this.isMenuvisible = false;
    }

    sendFCMToken(token) {
        let obj = { token: token }
        return this.httpClient.post(environment.apiURL + 'updateToken', obj)
            .toPromise()
            .then(this.handleData)
            .catch(this.handleError);
    }

    private handleData(res: any) {
        const body = res;
        return body || {};
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}