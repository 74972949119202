import { Component } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormBuilder } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { constant } from './core/helpers/global.helper';
import { AppLoaderService } from './core/services/app-loader.service';
import { CommonService } from './core/services/common.service';
import { ToastService } from './core/services/toast.service';
// import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'busnow';
  dateFormat: any;
  constructor(private common: CommonService,
    private loadingService: AppLoaderService,
    private toastService: ToastService,
    private router: Router,
    private localstore: LocalStorageService,
    private fb: FormBuilder) {
    this.dateFormat = constant().app.dateFormat;
    this.getMaster();
  }

  getMaster() {
    this.common.getMasterDetail().subscribe((res) => {
      if (res?.data?.result?.length != 0) {
        this.localstore.store('masterdata', res?.data);
      } else {

      }
    });
  }
  

}
